<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.cashOutVouchers') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shops') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="statusModel" :options="ticketStatuses" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.status') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-offset-2 p-md-1 p-text-right">
                        <Button :label="$t('buttons.getData')" class="p-button-raised p-button-success" @click="applyFiltersOnly()" />
                   </div>
                   <div  class="p-col-6  p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                </div>
                <Fieldset :legend="$t('search.byId')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="ticketSerial" />
                                <label>{{ $t('bets.voucherSerial') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right">
                            <Button :label="$t('buttons.searchData')" class="p-button-raised p-button-warning" @click="applySerialOnly()" />
                        </div>
                          
                    </div>
                </Fieldset>

                <DataTable
                    :lazy="true"
                    :totalRecords="totalItems"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    v-model:expandedRows="expandedRows"
                >
                    <template #header> </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :header="$t('general.status')">
                        <template #body="{ data }">
                            <Button :label="getStatusName(data)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + getStatusColor(data) + ' p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column field="serial" :header="$t('bets.voucherSerial')" :sortable="true"></Column>
                    <Column field="createDate" :header="$t('bets.createDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.createDate) }} </template>
                    </Column>
                    <Column field="expireDate" :header="$t('bets.expireDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.expireDate) }} </template>
                    </Column>
                    <Column field="amount" :header="$t('bets.amount')" :sortable="true">
                        <template #body="{ data }"> {{ formatCurrency(data.amount) }} </template>
                    </Column>
                    <Column field="shopId" :header="$t('bets.shopName')">
                        <template #body="{ data }">{{ shopsDictionary[data.shopId] }}</template>
                    </Column>
                    <Column field="payInfo" :header="$t('bets.winnerId')">
                        <template #body="{ data }">{{ data.payInfo != null ? data.payInfo.playerCnp : '' }}</template>
                    </Column>
                    <Column field="payInfo" :header="$t('bets.winnerName')">
                        <template #body="{ data }">{{ data.payInfo != null ? data.payInfo.playerName : '' }}</template>
                    </Column>
                    <Column field="payInfo" :header="$t('bets.payDate')">
                        <template #body="{ data }">{{ data.payInfo != null ? formatCSDateAndTime(data.payInfo.payDate) : '' }}</template>
                    </Column>
                    <Column field="payInfo" :header="$t('bets.paidBy')">
                        <template #body="{ data }">{{ data.payInfo != null ? getPaidBy(data.payInfo.cashierId) : '' }}</template>
                    </Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="{ data }"> <Button v-if="getStatusName(data) == 'Expired'" :label="$t('buttons.reactivateVoucher')" class="p-button-rounded p-button-warning p-mr-2" @click="reactivateVoucher(data.serial)" /> </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem"> </Column>
                    <template #expansion="slotProps">
                        <EditVoucher :serial="slotProps.data.serial" :showContent="getStatusName(slotProps.data) == 'Pending'"></EditVoucher>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../service/BoApi';
import { BoSaveFilesApi } from '../../service/BoSaveFilesApi';

import settings from '../../settings/generalSettings';
import { uiSettings } from '../../settings/uiSettings';
import EditVoucher from './Components/EditVoucher.vue';
export default {
    name: 'vouchers',
    components: { EditVoucher },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            cashiersDictinary: [],
            shopsDictionary: [],
            startDateModel: null,
            endDateModel: null,
            BoApi: new BoApi(),
            BoSaveFilesApi: new BoSaveFilesApi(),
            ticketStatuses: settings.VouchersStatuses,
            statusModel: { name: 'All', value: 100 },
            shops: [],
            users: [],
            items: [],
            shopsModel: { name: 'All', id: '0' },
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalItems: 0,
            ticketSerial: '',
            term: '',
            expandedRows: [],
            isReportDownloading:false
        };
    },
    props: {},
    computed: {},
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { name: 'All', id: '0' };
            }
        },
        statusModel() {
            if (!this.notNullOrEmptyObject(this.statusModel)) {
                this.statusModel = { name: 'All', value: 100 };
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.getShops();
        this.getCashierUsers();
        this.readItems();
    },
    methods: {
        getPaidBy(cashierId) {
            return cashierId != null ? this.cashiersDictinary[cashierId] : this.$t('system.terminal');
        },
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.createShopsDictionary();
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getCashierUsers() {
            this.BoApi.getAllUsers()
                .then((userResponse) => {
                    this.users = userResponse.data;
                    this.createCashiersDictinary();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        createShopsDictionary() {
            for (let i = 0; i < this.shops.length; i++) {
                this.shopsDictionary[this.shops[i].id] = this.shops[i].name;
            }
        },
        createCashiersDictinary() {
            for (let i = 0; i < this.users.length; i++) {
                this.cashiersDictinary[this.users[i]._id] = this.users[i].username;
            }
        },
        applyFiltersOnly() {
            this.ticketSerial = '';
            this.readItems();
        },
        applySerialOnly() {
            this.shopsModel = { name: 'All', id: '0' };
            this.readItems();
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
        },
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;

            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                startDate: this.formatApiDate(this.startDateModel),
                endDate: this.formatApiDate(this.endDateModel),
                shop: this.shopsModel.id,
                status: this.statusModel.value,
                ticketSerial: this.ticketSerial,
            };
            this.BoApi.getVouchers(params)
                .then((response) => {
                    if (response.data.data) {
                        this.items = response.data.data;
                    }
                    this.totalItems = response.data.total;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.checkForUnauthorizedError(error);
                });
        },
        saveXls() {
            this.isReportDownloading = true;
            this.BoSaveFilesApi.getVouchersXls(this.formatApiDate(this.startDateModel),this.formatApiDate(this.endDateModel),this.shopsModel.id,
             this.statusModel.value)
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Vouchers' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportDownloading = false;
                });
        },
        getStatusName(rowData) {
            if (this.notNullOrEmptyObject(rowData.payInfo)) {
                return this.$t('bets.status.paid');
            } else {
                var expireDate = new Date(rowData.expireDate);
                var today = new Date();
                if (expireDate < today) {
                    return this.$t('bets.status.expired');
                } else {
                    return this.$t('bets.status.pending');
                }
            }
        },
        getStatusColor(rowData) {
            if (this.notNullOrEmptyObject(rowData.payInfo)) {
                return 'info';
            } else {
                var expireDate = new Date(rowData.expireDate);
                var today = new Date();
                if (expireDate < today) {
                    return 'warning';
                } else {
                    return 'success';
                }
            }
        },
        reactivateVoucher(voucherSerial) {
            this.BoApi.reactivateVoucher(voucherSerial)
                .then(() => {
                    this.showToast('success', this.$t('bets.alerts.voucherActivateSuccess'));
                })
                .catch((error) => {
                    this.loading = false;
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('bets.alerts.voucherActivateError'));
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>
