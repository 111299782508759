<template>
    <div class="p-card p-grid p-shadow-3 p-pl-2" v-show="showContent">
        <Toast />
        <div class="p-col-12 p-xs-12">
            <div class="p-field p-grid">
                <label for="sortOrder" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('bets.newAmount') }} </label>
                <div class="p-col-12 p-md-4">
                    <InputText style="width: 100%" id="sortOrder" type="number" v-model="amount" />
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="sortOrder" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('bets.amountChangeReason') }} </label>
                <div class="p-col-12 p-md-4">
                    <InputText style="width: 100%" id="sortOrder" v-model="reason" />
                </div>
            </div>
        </div>
        <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
    </div>
</template>
<script>
import { BoApi } from '../../../service/BoApi';

export default {
    name: 'editVoucher',
    props: {
        serial: {
            type: String,
        },
        showContent: {
            type: Boolean,
        },
    },
    data() {
        return {
            BoApi: new BoApi(),
            amount: 0,
            reason: '',
        };
    },

    methods: {
        submitForm() {
            this.BoApi.updateVoucher(this.serial, this.amount, this.reason)
                .then(() => {
                    this.showToast('success', this.$t('bets.alerts.voucherUpdateSuccess'));
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('bets.alerts.voucherUpdateError'));
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>


